@charset "UTF-8";
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.i_html {
  height: 100%; }
  .i_html--is_wrap {
    overflow: hidden;
    pointer-events: none; }
  .i_html--is_disabled {
    pointer-events: none;
    overflow-y: hidden;
    /* !!! */
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */ }

.i_body {
  font-family: "Roboto";
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: transparent;
  height: 100%;
  min-height: 100%; }

.i_html--is_base.i_html--is_disabled .i_body {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
  /* WebKit - Safari, Chrome, Opera */ }
  .i_html--is_base.i_html--is_disabled .i_body::-webkit-scrollbar {
    width: 0;
    height: 0; }
  .i_html--is_base.i_html--is_disabled .i_body > * {
    -ms-overflow-style: auto;
    /* IE 10+ */ }

#bx-panel #bx-panel-top,
#bx-panel #bx-panel-site-toolbar {
  z-index: 994 !important;
  pointer-events: all; }

.i_wrap {
  display: flex;
  flex-direction: column;
  min-height: 100%; }

.i_html--is_base.i_html--is_disabled .i_wrap {
  overflow-y: scroll; }

.i_wrap__1 {
  flex: 1 0 auto; }

.i_wrap__2 {
  flex: 0 0 auto; }

.i_wrap__h {
  z-index: 990; }
  .i_html--is_base.i_html--is_disabled .i_wrap__h {
    pointer-events: auto; }

.i_wrap__b {
  margin-top: -1px; }

.i_test {
  display: flex;
  flex-direction: column;
  min-height: 100%; }

.i_html--is_base.i_html--is_disabled .i_test {
  overflow-y: scroll; }

.i_test__1 {
  flex: 1 0 auto; }

.i_test__2 {
  flex: 0 0 auto; }

.i_test__h {
  z-index: 990; }
  .i_html--is_base.i_html--is_disabled .i_test__h {
    pointer-events: auto; }

.i_test__b {
  margin-top: -1px; }

.i_cf:after {
  content: "";
  display: table;
  clear: both; }

.i_disable-mouse {
  pointer-events: none; }

.i_scroller {
  position: absolute;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
  text-size-adjust: none; }

.i_screen {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  pointer-events: none; }
  .i_screen > * {
    pointer-events: auto; }

.i_load {
  z-index: 980;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  background-color: white; }
  .i_load {
    z-index: -1000; }
  .i_load.out {
    z-index: 1000; }

.i_back {
  z-index: -200;
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  height: 100vh;
  box-sizing: border-box;
  transition: transform .5s 0s; }
  .i_html--is_base.i_html--is_disabled .i_back {
    overflow-y: scroll; }
  .i_back__bg {
    z-index: -1;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    background-image: url("/local/images/back_3.png");
    background-repeat: no-repeat; }
    @media all and (min-width: 48em) {
      .i_back__bg {
        background-size: cover;
        background-position: bottom center; } }
  .i_back:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(7, 108, 208, 0.25);
    box-sizing: border-box; }
  .uk-modal-page .i_back {
    overflow-y: scroll; }

.i_wrapper--1 {
  padding-top: 10rem;
  margin-top: -10rem;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25); }

.i_wrapper-slide, .i_wrapper-slide--1, .i_wrapper-slide--2 {
  transform: translate3d(0, 0, 0);
  transition: height .65s 0s; }
  .i_wrapper-slide.active, .i_wrapper-slide--1.active, .i_wrapper-slide--2.active {
    height: 0; }

.i_section-x {
  position: relative;
  width: 100%;
  padding: 0 1px;
  background-color: transparent;
  padding-bottom: 10rem; }
  .i_section-x__body {
    margin: 0 -1px;
    margin-bottom: -10rem; }
  .i_section-x__back {
    z-index: -2;
    display: block;
    position: absolute;
    top: -100%;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.5);
    background-color: white;
    transform: translate3d(0, 0, 0) skewY(-8deg);
    transform-origin: 50% 0; }

.i_section-1 {
  z-index: 1;
  position: relative;
  width: 100%;
  background-color: transparent;
  margin: 0;
  transform: translate3d(0, 0, 0);
  transform-origin: 50% 0;
  transition: 1s 0s; }
  @media all and (min-width: 0.0625em) {
    .i_section-1 {
      padding-top: 1rem;
      padding-bottom: 14rem; } }
  @media all and (min-width: 36em) {
    .i_section-1 {
      padding-top: 2rem;
      padding-bottom: 10rem; } }
  @media all and (min-width: 48em) {
    .i_section-1 {
      padding-top: 3rem;
      padding-bottom: 8rem; } }
  @media all and (min-width: 62em) {
    .i_section-1 {
      padding-top: 4rem;
      padding-bottom: 4rem; } }
  .i_html--is_base.i_html--is_disabled .i_section-1 {
    transform: translate3d(0, -150%, 0);
    transform-origin: 50% 0; }
  .i_section-1__back {
    display: block;
    position: absolute;
    top: -100%;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.25);
    transform: translate3d(0, 0, 0) skewY(-8deg);
    transform-origin: 50% 0;
    text-align: center; }
    .i_section-1__back-bg {
      display: block;
      position: absolute;
      top: 50%;
      bottom: -50%;
      left: 0;
      right: 0;
      margin-top: -79px;
      transform: translate3d(0, 0, 0) skewY(8deg);
      transform-origin: 50% 0;
      background-color: #f4f4f4;
      background-image: url("/local/images/back_1.png");
      background-repeat: no-repeat;
      background-position: top center; }
    .i_section-1__back .container {
      position: relative;
      margin: 0 auto;
      width: 100%;
      max-width: 1280px;
      height: 100%;
      background-color: transparent; }
      .i_section-1__back .container .i_obj-1 {
        display: block;
        position: absolute;
        bottom: -8px;
        right: 0%;
        width: 224px;
        height: 280px;
        transform: translate3d(0, 0, 0) skewY(8deg);
        transform-origin: 50% 0;
        background-image: url("/local/images/t.png");
        background-repeat: no-repeat;
        background-position: top center;
        opacity: .5; }
        @media all and (min-width: 0.0625em) and (max-width: 47.9375em) {
          .i_section-1__back .container .i_obj-1 {
            display: none; } }
        @media all and (min-width: 75em) {
          .i_section-1__back .container .i_obj-1 {
            right: 2%; } }
  .i_section-1__front {
    z-index: 1;
    display: block;
    position: absolute;
    top: -100%;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    transform: translate3d(0, 0, 0) skewY(-8deg);
    transform-origin: 50% 0;
    text-align: center;
    pointer-events: none; }
    .i_section-1__front .container {
      position: relative;
      margin: 0 auto 0 auto;
      width: 100%;
      max-width: 1280px;
      height: 100%;
      background-color: transparent; }
    .i_section-1__front .i_face {
      z-index: 1;
      display: block;
      position: absolute;
      bottom: -24px;
      width: 240px;
      height: 228px;
      transform: translate3d(0, 0, 0) skewY(8deg);
      transform-origin: 50% 0; }
      @media all and (min-width: 0.0625em) {
        .i_section-1__front .i_face {
          right: 20%; } }
      @media all and (min-width: 48em) {
        .i_section-1__front .i_face {
          right: 15%; } }
      @media all and (min-width: 62em) {
        .i_section-1__front .i_face {
          right: 10%; } }
      .i_section-1__front .i_face div:nth-child(1) {
        top: 8px;
        left: 8px;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url("/local/images/face.png");
        filter: blur(16px); }
      .i_section-1__front .i_face div:nth-child(2) {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url("/local/images/face.png"); }

.i_section-2 {
  position: relative;
  background-color: transparent; }
  @media all and (min-width: 0.0625em) {
    .i_section-2 {
      padding-top: 4rem;
      padding-bottom: 2rem; } }
  @media all and (min-width: 36em) {
    .i_section-2 {
      padding-top: 4rem;
      padding-bottom: 2rem; } }
  @media all and (min-width: 48em) {
    .i_section-2 {
      padding-top: 4rem;
      padding-bottom: 3rem; } }
  @media all and (min-width: 62em) {
    .i_section-2 {
      padding-top: 4rem;
      padding-bottom: 3rem; } }

.i_section-3 {
  position: relative;
  /*&:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0; right: 4%;
        width: 240px;
        height: 228px;
        background-image: url('/local/images/face.png');
    }*/ }
  .i_section-3__back {
    z-index: -1;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate3d(0, 0, 0) skewY(-8deg);
    transform-origin: 50% 0; }
    .i_section-3__back div:nth-child(1n) {
      display: block;
      position: absolute;
      top: -25%;
      right: 0;
      bottom: -35%;
      left: 0;
      background-color: rgba(3, 44, 85, 0.15); }
    .i_section-3__back div:nth-child(2n) {
      display: block;
      position: absolute;
      top: 25%;
      right: 0;
      bottom: -35%;
      left: 0;
      background: rgba(3, 44, 85, 0.15);
      /* Old browsers */
      background: -moz-linear-gradient(top, rgba(3, 44, 85, 0.15) 0%, rgba(0, 6, 11, 0.75) 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(3, 44, 85, 0.15)), color-stop(100%, rgba(0, 6, 11, 0.75)));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(top, rgba(3, 44, 85, 0.15) 0%, rgba(0, 6, 11, 0.75) 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(top, rgba(3, 44, 85, 0.15) 0%, rgba(0, 6, 11, 0.75) 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(top, rgba(3, 44, 85, 0.15) 0%, rgba(0, 6, 11, 0.75) 100%);
      /* IE10+ */
      background: linear-gradient(to bottom, rgba(3, 44, 85, 0.15) 0%, rgba(0, 6, 11, 0.75) 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-white', endColorstr='$color-black',GradientType=0 );
      /* IE6-9 */ }
  .i_section-3__i {
    background-color: rgba(0, 0, 0, 0.15); }

.i_section-4 {
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.i_section-5 {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem; }
  .i_section-5:before {
    content: '';
    z-index: -1;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: .5s 0;
    background: rgba(0, 0, 0, 0.5);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(3, 44, 85, 0) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.5)), color-stop(100%, rgba(3, 44, 85, 0)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(3, 44, 85, 0) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(3, 44, 85, 0) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(3, 44, 85, 0) 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(3, 44, 85, 0) 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-white', endColorstr='$color-black',GradientType=0 );
    /* IE6-9 */ }
  .i_section-5 .i_header {
    margin-top: -0.125rem;
    margin-bottom: -1.125rem; }
    .i_section-5 .i_header span {
      padding: .5rem 1.5rem; }
  .i_section-5 .uk-placeholder {
    margin-top: 0;
    margin-bottom: 0; }
    @media all and (min-width: 0.0625em) {
      .i_section-5 .uk-placeholder {
        padding: 3rem 1rem 2rem 1rem; } }
    @media all and (min-width: 36em) {
      .i_section-5 .uk-placeholder {
        padding: 3rem 1.5rem 2rem 1.5rem; } }
    @media all and (min-width: 48em) {
      .i_section-5 .uk-placeholder {
        padding: 3rem 2rem 2rem 2rem; } }
    @media all and (min-width: 62em) {
      .i_section-5 .uk-placeholder {
        padding: 3rem 3rem 3rem 3rem; } }
    @media all and (min-width: 75em) {
      .i_section-5 .uk-placeholder {
        padding: 3rem 4rem 3rem 4rem; } }
    .i_section-5 .uk-placeholder ul li {
      margin-bottom: .75rem;
      line-height: 1.125rem; }

.i_section-6 {
  position: relative;
  overflow-x: hidden; }
  .i_section-6 h3 {
    margin-bottom: -1rem; }

.i_section-2 .card {
  transition: .25s 0s;
  background-color: #d4d4d4;
  background-image: url("/local/images/back_1.png");
  background-repeat: no-repeat;
  background-position: top center;
  border: 0 none transparent; }
  @media all and (min-width: 0.0625em) {
    .i_section-2 .card {
      min-height: 10rem; } }
  @media all and (min-width: 62em) {
    .i_section-2 .card {
      min-height: 15rem; } }
  .i_section-2 .card:hover {
    z-index: 0;
    text-decoration: none;
    box-shadow: 0 0 128px 0 rgba(114, 181, 255, 0.75); }
  .i_section-2 .card h4 {
    line-height: 1.375rem;
    font-weight: 500; }
  .i_section-2 .card ul {
    padding: 0 0 0 1rem;
    margin: 0; }
  .i_section-2 .card ul li {
    line-height: 1.125rem;
    margin: .5rem; }
  .i_section-2 .card ul.text-muted li {
    color: #6c757d; }

.i_section-2 .card-head {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 4px; }

.i_section-2 .card-body {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  padding: 2rem; }

.i_section-2 .row div:nth-child(1) .card {
  background-position: top left; }

.i_section-2 .row div:nth-child(2) .card {
  background-position: top center; }

.i_section-2 .row div:nth-child(3) .card {
  background-position: top right; }

.i_section-2 .i_card {
  transition: .25s 0s;
  background-color: #d4d4d4;
  background-image: url("/local/images/back_1.png");
  background-repeat: no-repeat;
  background-position: top center;
  border: 0 none transparent;
  /*ul {
            //font-size: .85rem;
            padding: 0 0 0 1rem;
            margin: 0;
        }
        ul li {
            line-height: 1.125rem;
            margin: .5rem;
        }
        ul li { color: #6c757d; }
        p {
            //transform: translate3d(0,300%,0);
        }*/ }
  .i_section-2 .i_card:hover {
    z-index: 0;
    text-decoration: none;
    box-shadow: 0 0 128px 0 rgba(114, 181, 255, 0.75); }
    .i_section-2 .i_card:hover .card-head:before {
      opacity: 0;
      transform: scale3d(2, 2, 2);
      transition: opacity .5s 0s, transform .5s 0s; }
    .i_section-2 .i_card:hover .card-head:after {
      opacity: 0;
      transition: opacity .5s 0; }
    .i_section-2 .i_card:hover h2, .i_section-2 .i_card:hover h2 > *,
    .i_section-2 .i_card:hover h3, .i_section-2 .i_card:hover h3 > *,
    .i_section-2 .i_card:hover h4, .i_section-2 .i_card:hover h4 > * {
      color: #003776 !important;
      border-bottom: 1px solid transparent;
      transition: color .5s 0s; }
    .i_section-2 .i_card:hover ul, .i_section-2 .i_card:hover li, .i_section-2 .i_card:hover p {
      color: #6c757d !important; }
    .i_section-2 .i_card:hover p {
      transform: translate3d(0, 0, 0);
      transition: transform .5s 0s; }
  .i_section-2 .i_card:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5); }
  .i_section-2 .i_card .card-head:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transition: opacity .5s 0s, transform .5s 0s; }
  .i_section-2 .i_card p, .i_section-2 .i_card ul, .i_section-2 .i_card li,
  .i_section-2 .i_card h2, .i_section-2 .i_card h2 > *,
  .i_section-2 .i_card h3, .i_section-2 .i_card h3 > *,
  .i_section-2 .i_card h4, .i_section-2 .i_card h4 > * {
    color: white !important;
    transition: transform .5s 0s; }
  .i_section-2 .i_card h2,
  .i_section-2 .i_card h3,
  .i_section-2 .i_card h4 {
    display: inline-block;
    line-height: 1.375rem;
    font-weight: 500;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25); }

.i_section-2 .i_card .card-head {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 4px; }

.i_section-2 .i_card .card-head:after {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  height: 100px;
  background: rgba(0, 0, 0, 0.5);
  /* Old browsers */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(3, 44, 85, 0) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.5)), color-stop(100%, rgba(3, 44, 85, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(3, 44, 85, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(3, 44, 85, 0) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(3, 44, 85, 0) 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(3, 44, 85, 0) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-white', endColorstr='$color-black',GradientType=0 );
  /* IE6-9 */
  transform: translate3d(0, 0, 0);
  transform-origin: 50% 0;
  opacity: 1;
  transition: opacity .5s 0; }

.i_section-2 .i_card .card-body {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  padding: 2rem; }

.i_section-2 .row div:nth-child(1) .i_card {
  background-position: top left; }

.i_section-2 .row div:nth-child(1) .i_card .card-head:before {
  background-image: url("/local/images/card_1.png"); }

.i_section-2 .row div:nth-child(2) .i_card {
  background-position: top center; }

.i_section-2 .row div:nth-child(2) .i_card .card-head:before {
  background-image: url("/local/images/card_2.png"); }

.i_section-2 .row div:nth-child(3) .i_card {
  background-position: top right; }

.i_section-2 .row div:nth-child(3) .i_card .card-head:before {
  background-image: url("/local/images/card_3.png"); }

.i_card-real {
  border: 0 none transparent;
  background-color: #f4f4f4;
  background-image: url("/local/images/real.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  overflow: hidden; }
  .i_card-real .card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(216, 188, 128, 0.25);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(216, 188, 128, 0.25) 0%, #a4a4a4 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(216, 188, 128, 0.25)), color-stop(100%, #a4a4a4));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(216, 188, 128, 0.25) 0%, #a4a4a4 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(216, 188, 128, 0.25) 0%, #a4a4a4 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(216, 188, 128, 0.25) 0%, #a4a4a4 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(216, 188, 128, 0.25) 0%, #a4a4a4 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-white', endColorstr='$color-black',GradientType=0 );
    /* IE6-9 */ }
    .i_card-real .card-overlay:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      bottom: -50%;
      left: 0;
      background: rgba(0, 0, 0, 0.25);
      /* Old browsers */
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, rgba(176, 119, 26, 0.25) 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.25)), color-stop(100%, rgba(176, 119, 26, 0.25)));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, rgba(176, 119, 26, 0.25) 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, rgba(176, 119, 26, 0.25) 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, rgba(176, 119, 26, 0.25) 100%);
      /* IE10+ */
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(176, 119, 26, 0.25) 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-white', endColorstr='$color-black',GradientType=0 );
      /* IE6-9 */
      transform: translate3d(0, 40%, 0) skewY(-8deg);
      transform-origin: 50% 0;
      transition: transform .25s 0s; }
  .i_card-real:hover .card-overlay:before {
    transform: translate3d(0, 0, 0) skewY(-8deg);
    transform-origin: 50% 0; }
  .i_card-real, .i_card-real:link, .i_card-real:visited, .i_card-real:hover, .i_card-real:active {
    text-decoration: none; }
    .i_card-real *, .i_card-real:link *, .i_card-real:visited *, .i_card-real:hover *, .i_card-real:active * {
      color: white !important;
      text-shadow: 0 0 16px #000000, 0 -1px 1px rgba(0, 0, 0, 0.25); }
  .i_card-real .card-body {
    position: relative; }

.i_card-will {
  border: 0 none transparent;
  background-color: #f4f4f4;
  background-image: url("/local/images/will.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  overflow: hidden; }
  .i_card-will .card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(216, 188, 128, 0.25);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(216, 188, 128, 0.25) 0%, #a4a4a4 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(216, 188, 128, 0.25)), color-stop(100%, #a4a4a4));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(216, 188, 128, 0.25) 0%, #a4a4a4 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(216, 188, 128, 0.25) 0%, #a4a4a4 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(216, 188, 128, 0.25) 0%, #a4a4a4 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(216, 188, 128, 0.25) 0%, #a4a4a4 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-white', endColorstr='$color-black',GradientType=0 );
    /* IE6-9 */ }
    .i_card-will .card-overlay:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      bottom: -50%;
      left: 0;
      background: rgba(0, 0, 0, 0.25);
      /* Old browsers */
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, rgba(176, 119, 26, 0.25) 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.25)), color-stop(100%, rgba(176, 119, 26, 0.25)));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, rgba(176, 119, 26, 0.25) 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, rgba(176, 119, 26, 0.25) 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, rgba(176, 119, 26, 0.25) 100%);
      /* IE10+ */
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(176, 119, 26, 0.25) 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-white', endColorstr='$color-black',GradientType=0 );
      /* IE6-9 */
      transform: translate3d(0, 40%, 0) skewY(-8deg);
      transform-origin: 50% 0;
      transition: transform .25s 0s; }
  .i_card-will:hover .card-overlay:before {
    transform: translate3d(0, 0, 0) skewY(-8deg);
    transform-origin: 50% 0; }
  .i_card-will, .i_card-will:link, .i_card-will:visited, .i_card-will:hover, .i_card-will:active {
    text-decoration: none; }
    .i_card-will *, .i_card-will:link *, .i_card-will:visited *, .i_card-will:hover *, .i_card-will:active * {
      color: white !important;
      text-shadow: 0 0 16px #000000, 0 -1px 1px rgba(0, 0, 0, 0.25); }
  .i_card-will .card-body {
    position: relative; }

.j_st-1, .j_st-2 {
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: #000000; }

.j_st-useful-1 {
  position: absolute;
  top: 0;
  width: 100%;
  height: 0px;
  font-size: 0;
  line-height: 0;
  background-color: #000000; }

.j_st-useful-2 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0px;
  font-size: 0;
  line-height: 0;
  background-color: #f00; }

.i_ctrl {
  background-color: #f00;
  text-align: right; }
  .i_ctrl__w {
    display: inline-block;
    position: absolute;
    top: 0;
    height: 0;
    background-color: #000000;
    transform: translate3d(0, 0, 0);
    transition: 0s 0s; }
    .i_ctrl__w--sticky {
      position: fixed;
      top: 5rem;
      transition: .5s 0s; }

.i_cbar {
  z-index: 1;
  position: sticky;
  position: -webkit-sticky;
  top: 5rem;
  height: 0rem;
  padding-top: 1px;
  text-align: right; }
  .i_cbar:before {
    content: '';
    display: block;
    position: relative;
    height: 0;
    margin-top: -1px; }
  .i_section-x .i_cbar {
    margin: 0 -1rem; }
  .i_cbar__x {
    z-index: 1;
    position: relative;
    display: block;
    width: 2rem;
    height: 2rem;
    margin: 1rem;
    border: 1px solid transparent;
    margin-left: auto;
    text-align: center;
    border-radius: 100%;
    color: #999999;
    cursor: pointer; }
    .i_cbar__x:hover, .i_cbar__x:active {
      color: #666666; }
    .i_cbar__x > * {
      pointer-events: none; }
    .i_cbar__x--m {
      opacity: 0;
      transform: scale3d(0, 0, 0);
      transition: .25s 0s; }
    .i_cbar__x i {
      line-height: 2rem; }

.i_card {
  margin-top: 1rem;
  background-color: white; }
  @media all and (min-width: 0.0625em) {
    .i_card__body {
      padding: 1rem; } }
  @media all and (min-width: 36em) {
    .i_card__body {
      padding: 1.5rem; } }
  @media all and (min-width: 48em) {
    .i_card__body {
      padding: 2rem; } }
  @media all and (min-width: 62em) {
    .i_card__body {
      padding: 3rem; } }
  @media all and (min-width: 75em) {
    .i_card__body {
      padding: 4rem; } }

.i_btn--close {
  position: sticky;
  position: -webkit-sticky;
  top: 80px;
  text-align: right; }

.i_b__p1 {
  text-align: right; }

.i_b__p2 {
  display: inline-block;
  position: absolute;
  top: 0;
  height: 0;
  background-color: #000000;
  transform: translate3d(0, 0, 0);
  transition: top 0s 0s; }
  .i_b__p2--sticky {
    position: fixed;
    top: 80px;
    transition: top .5s 0s; }

.i_b__t_h {
  position: absolute;
  top: 0;
  width: 100%;
  height: 0px;
  font-size: 0;
  line-height: 0;
  background-color: #000000; }

.i_b__t_f {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0px;
  font-size: 0;
  line-height: 0;
  background-color: #000000; }

.i_x {
  z-index: 99;
  position: absolute;
  width: 2rem;
  height: 2rem;
  right: 1rem;
  margin-top: 1rem;
  display: block;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 100%; }
  @media all and (min-width: 0.0625em) and (max-width: 47.9375em) {
    .i_x {
      top: 4rem; } }
  @media all and (min-width: 48em) and (max-width: 61.9375em) {
    .i_x {
      top: 0; } }

.i_p {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  overflow-y: scroll;
  visibility: hidden;
  transition: 0s 0s; }
  .i_p__o {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: 0;
    transform: translate3d(0, 0, 0);
    transition: .5s; }
  .i_p__x {
    z-index: 1;
    position: absolute;
    display: block;
    width: 2rem;
    height: 2rem;
    right: 1rem;
    top: 1rem;
    border-radius: 100%;
    background-color: #0077fe;
    color: white;
    text-align: center;
    cursor: pointer; }
    .i_p__x--m {
      opacity: 0;
      transform: scale3d(0, 0, 0);
      transition: .25s 0s; }
    .i_p__x > * {
      pointer-events: none; }
    .i_p__x i {
      line-height: 2rem; }
  .i_p__w {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    overflow-y: scroll;
    visibility: hidden;
    transition: 0s 0s; }
    .touch .i_p__w {
      -webkit-overflow-scrolling: touch; }
  .i_p__d {
    position: relative;
    width: 100%; }
  .i_p--is_active {
    visibility: visible;
    transition: 0s 0s; }
  .i_p--is_active .i_p__x--m {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transition: .25s .5s; }
  .i_p--is_active .i_p__o {
    opacity: .5;
    transition: 1.5s 0s; }
  .i_p--is_active .i_p__w {
    visibility: visible;
    transition: 0s .5s; }
  .i_p--1 .i_p__w {
    padding: 0 4rem; }
  .i_p--1 .i_p__d {
    margin: 4rem auto;
    padding: 4rem;
    background-color: white;
    box-shadow: 0 0 0 0 transparent; }
  .i_p--1 .i_p__x--m {
    display: block; }
  .i_p--1 .i_p__x--d {
    display: none; }
  .i_p--2 .i_p__w {
    padding: 0 4rem; }
  .i_p--2 .i_p__d {
    margin: 4rem auto;
    padding: 4rem;
    background-color: white;
    box-shadow: 0 0 0 0 transparent; }
  .i_p--2 .i_p__x--m {
    display: none; }
  .i_p--2 .i_p__x--d {
    display: block; }

.i_icon {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto 1rem auto;
  border-radius: 100%;
  transition: .5s 0s; }
  a:hover .i_icon:before {
    transform: scale3d(1.2, 1.2, 1.2); }
  .i_icon:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    transition: .25s 0s; }
  .i_icon:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden; }
  .i_icon--1:after {
    background-image: url("/local/images/icon_1.svg"); }
  .i_icon--2:after {
    background-image: url("/local/images/icon_2.svg"); }
  .i_icon--3:after {
    background-image: url("/local/images/icon_3.svg"); }
  .i_icon--4:after {
    background-image: url("/local/images/icon_4.svg"); }
  .i_icon--5:after {
    background-image: url("/local/images/icon_5.svg"); }
  .i_icon--6:after {
    background-image: url("/local/images/icon_6.svg"); }
  .i_icon--7:after {
    background-image: url("/local/images/icon_7.svg"); }
  .i_icon--8:after {
    background-image: url("/local/images/icon_8.svg"); }
  .i_icon--9:after {
    background-image: url("/local/images/icon_9.svg"); }

.i_color-i a {
  display: inline-block;
  text-decoration: none !important;
  /*&, & *,
        &:link, &:link *,
        &:visited, &:visited * { color: rgba($color-white, .75) !important; }
        &:hover, &:hover *,
        &:active, &:active *  { color: rgba(#FFD86E, 1) !important; }
        */ }

.i_color--base {
  color: rgba(0, 0, 0, 0.25); }

.i_color--1 {
  color: #06529f; }

.i_color--2 {
  color: #b0771a !important; }

.i_color--3 {
  color: #ffd86e !important; }

.i_text-accent--1 {
  text-shadow: 0 8px 8px #c2c2c2, 0 -1px 1px #ffffff;
  color: #f2f2f2; }

.i_text-size--1 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2rem;
  letter-spacing: -1px; }

.i_text-size--2 {
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 2rem; }
  @media all and (min-width: 0.0625em) and (max-width: 47.9375em) {
    .i_text-size--2 {
      font-size: 1rem;
      line-height: 1.5rem; } }
  .i_text-size--2 strong {
    font-weight: 500; }

.i_text-size--3 {
  font-size: 0.85rem; }

.i_arrow-box {
  position: relative;
  background: #fff;
  margin-bottom: 6rem; }
  .i_arrow-box .card-body:after {
    content: "";
    position: absolute;
    pointer-events: none;
    overflow: hidden;
    height: 5rem;
    width: 5rem;
    top: 100%;
    left: 50%;
    margin-top: 1rem;
    margin-left: -2.5rem;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    background-image: url("/local/images/face_2_small.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; }
  .i_arrow-box:after, .i_arrow-box:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .i_arrow-box:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 8px;
    margin-left: -8px; }
  .i_arrow-box:before {
    border-color: rgba(204, 204, 204, 0);
    border-top-color: #ccc;
    border-width: 9px;
    margin-left: -9px; }

.i_bg-primary {
  background-color: #004ea9; }

.i_list--1 a {
  padding: .5rem; }

h1, h2, h3, h4, h5, h6,
div, p, span, blockquote, a, small, big, b, strong, u, i, ul, ol, li, dl, dt, dd, sup, sub, button, input, label, textarea, select, option {
  font-family: "Roboto"; }

h1, .h1 {
  color: #000000 !important;
  font-size: 2rem;
  font-weight: 500; }
  @media all and (min-width: 0.0625em) and (max-width: 47.9375em) {
    h1, .h1 {
      font-size: 1.5rem; } }
  .i_title h1, .i_title .h1 {
    font-weight: 300;
    margin-bottom: 1.25rem; }

h2, .h2 {
  font-size: 1.5rem;
  font-weight: 500; }

h3, .h3 {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem; }

h4, .h4 {
  font-size: 1.125rem;
  font-weight: 500; }

h5, .h5 {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0; }

h6, .h6 {
  font-size: .75rem;
  font-weight: 500; }

blockquote, .blockquote {
  font-size: 1rem; }

small {
  font-size: 14px; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

a, a * {
  color: #004ea9; }

a:hover, a:active, a:hover *, a:active * {
  color: #003776; }

.uk-light a, .uk-light a * {
  color: rgba(255, 255, 255, 0.75); }

.uk-light a:hover, .uk-light a:active, .uk-light a:hover *, .uk-light a:active * {
  color: white; }

.i_bg-primary a, .i_bg-primary a * {
  display: block;
  background-color: #004ea9; }

.i_bg-primary a:hover, .i_bg-primary a:active, .i_bg-primary a:hover *, .i_bg-primary a:active * {
  background-color: #003776; }

ul.list-style {
  list-style: none;
  padding: 0; }
  ul.list-style > li {
    position: relative;
    padding: 0 0 0 1.25rem;
    /*&:before {
            content: '•';
            position: absolute;
            display: inline-block;
            overflow: hidden;
            width: .75rem;
            height: 1px;
            //line-height: 1px;
            //top: .625rem;
            left: 0;
            //margin-top: -1px;
            font-size: 50rem;
            text-indent: -5rem;
            background-color: #000;
        }*/ }
    ul.list-style > li:before {
      content: '—';
      position: absolute;
      left: 0; }

ul.list-check {
  list-style: none;
  padding: 0; }
  ul.list-check > li {
    position: relative;
    margin: 0 0 .5rem 0;
    padding: 0 0 0 2.5rem; }
    ul.list-check > li:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 2rem;
      height: 1.5rem;
      line-height: 1.5rem;
      text-align: center;
      background-image: url("/local/images/list-check.png");
      background-repeat: no-repeat;
      background-position: center center; }

ol.list-style {
  /*&--1 > li {
        position: relative;
        margin: 0 0 .75rem 0;
        padding: 0 0 0 2.5rem;
        &:before {
            content: counter(li);
            counter-increment: li;
            position: absolute;
            top: -0.375rem;
            left: 0;
            width: 2rem;
            height: 2rem;
            line-height: 2rem;
            text-align: center;
            border: 1px solid rgba(lighten($color-black, 85%), 1);
            border-radius: 100%;
        }
    }*/ }
  ol.list-style {
    counter-reset: li;
    list-style: none;
    padding: 0; }
    ol.list-style > li {
      position: relative;
      margin: 0 0 .75rem 0;
      padding: 0 0 0 2.5rem; }
      ol.list-style > li:before {
        content: counter(li);
        counter-increment: li;
        position: absolute;
        top: -0.375rem;
        left: 0;
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        border: 1px solid #d9d9d9;
        border-radius: 100%; }
  ol.list-style--1 {
    counter-reset: list-order;
    list-style: none;
    padding: 0; }
  ol.list-style--1 > span {
    margin: 0 0 .75rem 0 !important; }
  ol.list-style--1 li {
    counter-increment: list-order;
    list-style-type: none;
    position: relative;
    margin: 0 0 .75rem 0;
    padding: 0 0 0 2.5rem; }
  ol.list-style--1 li:before {
    content: counter(list-order);
    position: absolute;
    top: -0.375rem;
    left: 0;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    border: 1px solid #d9d9d9;
    border-radius: 100%; }

.i_blockquote {
  position: relative;
  padding-left: 1.25rem;
  border-left: 1px dashed #bfbfbf; }
  .i_blockquote:before {
    content: "\201D";
    font-family: serif;
    position: absolute;
    right: 100%;
    margin-right: 1.25rem;
    height: 2rem;
    overflow: hidden;
    font-size: 3rem;
    line-height: 2.75rem;
    font-weight: bold;
    text-align: center;
    color: #bfbfbf; }
    @media all and (min-width: 0.0625em) and (max-width: 61.9375em) {
      .i_blockquote:before {
        display: none; } }

.i_logo {
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: black; }
  .i_logo__sign {
    display: inline-block;
    vertical-align: middle;
    margin-top: -8px;
    margin-bottom: -8px; }
  @media all and (min-width: 0.0625em) and (max-width: 47.9375em) {
    .i_logo__name {
      display: block;
      font-size: 12px;
      line-height: 10px;
      text-indent: 2px;
      /*text-align: right;*/ } }
  .i_logo img {
    display: block;
    margin-right: 8px; }
  .i_logo:link, .i_logo:visited,
  .i_logo:link *, .i_logo:visited * {
    color: black; }
  .i_logo:hover, .i_logo:active,
  .i_logo:hover *, .i_logo:active * {
    color: black;
    text-decoration: none; }

.i_navbar {
  z-index: 9999;
  position: relative;
  min-height: 5rem;
  background-color: #f4f4f4;
  background-image: url("/local/images/back_1.png");
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 20px;
  padding-bottom: 20px; }

.i_nav-button-prev,
.i_nav-button-next {
  z-index: auto;
  background-color: white;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.25);
  color: #999; }
  .i_nav-button-prev:hover,
  .i_nav-button-next:hover {
    background-color: white;
    color: #666666 !important;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5); }

.i_section-6 .card {
  transition: .25s 0s; }
  .i_section-6 .card:hover {
    text-decoration: none;
    box-shadow: 0 0 64px 0 rgba(114, 181, 255, 0.75); }
  .i_section-6 .card:before {
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5); }

.i_navbar .container-fluid {
  max-width: 1280px; }

.i_m {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  overflow-y: hidden;
  visibility: hidden;
  pointer-events: none;
  transition: 0s 0s; }
  .i_m__o {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: 0;
    transform: translate3d(0, 0, 0);
    transition: .5s; }
  .i_m__ctrl {
    z-index: 1;
    position: sticky;
    position: -webkit-sticky;
    top: 5rem;
    height: 0;
    padding-top: 1px;
    text-align: right; }
    .i_m__ctrl:before {
      content: '';
      display: block;
      position: relative;
      height: 0;
      margin-top: -1px; }
  .i_m__x {
    z-index: 1;
    position: relative;
    display: block;
    width: 2rem;
    height: 2rem;
    margin: 1rem;
    border: 1px solid transparent;
    margin-left: auto;
    text-align: center;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    color: #999999;
    cursor: pointer; }
    .i_m__x:hover, .i_m__x:active {
      color: #666666; }
    .i_m__x > * {
      pointer-events: none; }
    .i_m__x--m {
      opacity: 0;
      transform: scale3d(0, 0, 0);
      transition: .25s 0s; }
    .i_m__x i {
      line-height: 2rem; }
  .i_m__w {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    visibility: hidden;
    transform: translate3d(0, 0, 0);
    transition: visibility 0s 0s; }
    .touch .i_m__w {
      -webkit-overflow-scrolling: touch; }
  .i_m__d {
    position: relative;
    width: 100%; }
  .i_m__body {
    position: relative;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden; }
    @media all and (min-width: 0.0625em) {
      .i_m__body {
        padding: 1rem 1rem; } }
    @media all and (min-width: 48em) {
      .i_m__body {
        padding: 1rem 1rem; } }
    @media all and (min-width: 62em) {
      .i_m__body {
        padding: 4rem 4rem; } }
  .i_m--is_active {
    visibility: visible;
    pointer-events: auto;
    transition: 0s 0s; }
  .i_m--is_active .i_m__x--m {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transition: .25s .5s; }
  .i_m--is_active .i_m__o {
    opacity: .5;
    transition: 1.5s 0s; }
  .i_m--is_active .i_m__w {
    z-index: 8888;
    visibility: visible;
    transition: visibility 0s .5s; }
  .i_m--1 .i_m__i {
    margin: 5rem 0 0 0; }
    @media all and (min-width: 0.0625em) {
      .i_m--1 .i_m__i {
        padding: 0rem; } }
    @media all and (min-width: 48em) {
      .i_m--1 .i_m__i {
        padding: 1rem; } }
    @media all and (min-width: 62em) {
      .i_m--1 .i_m__i {
        padding: 4rem; } }
  .i_m--1 .i_m__d {
    max-width: 1250px;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 0 0 0 transparent; }
  .i_m--1 .i_m__x--m {
    display: none; }
  .i_m--1 .i_m__x--d {
    display: block; }
  .i_m--2 .i_m__i {
    margin: 5rem 0 0 0; }
    @media all and (min-width: 0.0625em) {
      .i_m--2 .i_m__i {
        padding: 0rem; } }
    @media all and (min-width: 48em) {
      .i_m--2 .i_m__i {
        padding: 1rem; } }
    @media all and (min-width: 62em) {
      .i_m--2 .i_m__i {
        padding: 4rem; } }
  .i_m--2 .i_m__d {
    max-width: 1250px;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 0 0 0 transparent; }
  .i_m--2 .i_m__x--m {
    display: none; }
  .i_m--2 .i_m__x--d {
    display: block; }
  .i_m--3 .i_m__i {
    margin: 5rem 0 0 0; }
    @media all and (min-width: 0.0625em) {
      .i_m--3 .i_m__i {
        padding: 0rem; } }
    @media all and (min-width: 48em) {
      .i_m--3 .i_m__i {
        padding: 1rem; } }
    @media all and (min-width: 62em) {
      .i_m--3 .i_m__i {
        padding: 4rem; } }
  .i_m--3 .i_m__d {
    max-width: 1250px;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 0 0 0 transparent;
    transform: translate3d(0, 100%, 0);
    transition: 0s 0s; }
  .i_m--3.i_m--is_active .i_m__d {
    transform: translate3d(0, 0, 0);
    transition: .75s .25s; }
  .i_m--3 .i_m__x--m {
    display: none; }
  .i_m--3 .i_m__x--d {
    display: block; }
  .i_m--4 .i_m__o {
    display: none; }
  .i_m--4 .i_m__i {
    min-height: 100%;
    background-color: #003776;
    padding-top: 1px;
    padding-bottom: 1px; }
    .i_m--4 .i_m__i:before {
      content: '';
      display: block;
      position: relative;
      height: 0;
      margin-top: -1px;
      padding-bottom: 5rem; }
    .i_m--4 .i_m__i:after {
      content: '';
      display: block;
      position: relative;
      height: 0;
      margin-bottom: -1px; }
  .i_m--4 .i_m__d {
    max-width: 1250px;
    margin: 0 auto;
    background-color: white;
    transform: translate3d(0, 500%, 0);
    transition: 0s 0s; }
    @media all and (min-width: 0.0625em) {
      .i_m--4 .i_m__d {
        margin: 0 auto; } }
    @media all and (min-width: 48em) {
      .i_m--4 .i_m__d {
        margin: 1rem auto; } }
    @media all and (min-width: 62em) {
      .i_m--4 .i_m__d {
        margin: 4rem auto; } }
  .i_m--4.i_m--is_active .i_m__o {
    display: none; }
  .i_m--4.i_m--is_active .i_m__d {
    transform: translate3d(0, 0, 0);
    transition: .75s .25s; }
  .i_m--4 .i_m__x--m {
    display: none; }
  .i_m--4 .i_m__x--d {
    display: block; }
  .i_m--5 .i_m__s {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: scroll; }
  .i_m--5 .i_m__o {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    background-color: #003776;
    opacity: 1;
    transition: 0s 0s; }
    .i_m--5 .i_m__o:after {
      display: none;
      content: '';
      display: block;
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      background-image: url("/local/images/back_5.jpg");
      background-repeat: no-repeat;
      background-position: bottom center;
      opacity: 0;
      transition: .0s .0s; }
  .i_m--5 .i_m__x {
    /*@include mappy-bp(xs lg) { margin-left: calc(100% - 1rem); margin-top: -1rem; }*/ }
  .i_m--5 .i_m__d {
    max-width: 960px;
    margin: 8rem auto 4rem auto;
    background-color: white;
    transform: translate3d(0, 500%, 0);
    transition: transform 0s 0s; }
  .i_m--5.i_m--is_active .i_m__s {
    transition: 0s .5s; }
  .i_m--5.i_m--is_active .i_m__o:after {
    display: none;
    opacity: 1;
    transition: 3s .5s; }
  .i_m--5.i_m--is_active .i_m__d {
    transform: translate3d(0, 0, 0);
    transition: transform 1s .25s; }
  .i_m--5 .i_m__x--m {
    display: none; }
  .i_m--5 .i_m__x--d {
    display: block; }
  .i_m--6 .i_m__o {
    background-color: transparent;
    overflow-y: auto;
    opacity: 1;
    transform: translate3d(0, -100%, 0);
    transition: .5s 0s; }
    .i_m--6 .i_m__o:before {
      content: '';
      display: block;
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      background-color: black;
      opacity: 1; }
    .i_m--6 .i_m__o:after {
      content: '';
      display: block;
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      background-image: url("/local/images/back_5.jpg");
      background-repeat: no-repeat;
      background-position: bottom center;
      opacity: 1; }
  .i_m--6 .i_m__i {
    margin: 5rem 0 0 0; }
    @media all and (min-width: 0.0625em) {
      .i_m--6 .i_m__i {
        padding: 1rem; } }
    @media all and (min-width: 48em) {
      .i_m--6 .i_m__i {
        padding: 1rem; } }
    @media all and (min-width: 62em) {
      .i_m--6 .i_m__i {
        padding: 4rem; } }
  .i_m--6 .i_m__d {
    max-width: 960px;
    margin: 0 auto;
    background-color: white;
    transform: translate3d(0, 500%, 0);
    transition: transform 0s 0s, visibility 0s 0s, opacity 0s 0s; }
  .i_m--6.i_m--is_active {
    z-index: 890; }
  .i_m--6.i_m--is_active .i_m__o {
    overflow-y: scroll;
    transform: translate3d(0, 0, 0);
    transition: .5s 0s; }
  .i_m--6.i_m--is_active .i_m__d {
    transform: translate3d(0, 0, 0);
    transition: transform 1s 0s, visibility 0s 0s, opacity 0s 0s; }
  .i_m--6 .i_m__x--m {
    display: none; }
  .i_m--6 .i_m__x--d {
    display: block; }

.btn-primary {
  background-color: #004ea9;
  border: 1px solid transparent;
  outline: none; }
  .btn-primary:hover, .btn-primary:active {
    background-color: #003776;
    border: 1px solid transparent;
    outline: none; }

.uk-light a, .uk-light a span {
  color: white;
  text-decoration: none !important;
  background-image: linear-gradient(transparent, transparent), linear-gradient(transparent, transparent), linear-gradient(to right, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25));
  background-repeat: no-repeat;
  background-position: 120%, 120%, 0 120%;
  background-size: 100% 4px; }

.uk-light a:hover span, .uk-light a:active span, .uk-light a:hover, .uk-light a:active {
  color: white;
  background-image: none; }

.uk-light .uk-active a {
  color: #FFFFFF; }
  .uk-light .uk-active a:hover, .uk-light .uk-active a:active {
    color: #FFFFFF; }

.uk-icon-button:link, .uk-icon-button:hover, .uk-icon-buttonactive, .uk-icon-button:visited {
  text-decoration: none; }

.uk-text-lead {
  font-size: 1.25rem; }

.lg-backdrop {
  background-color: transparent; }

.lg-backdrop.in {
  z-index: 780; }

.lg-outer {
  z-index: 790;
  transition: 0s 0s !important; }
  .lg-outer:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f4f4f4;
    background-image: url("/local/images/back_1.png");
    background-repeat: no-repeat;
    background-position: top center; }
  .i_html--is_disabled .lg-outer {
    overflow-y: scroll; }

.lg-inner {
  /*padding-top:80px;*/ }

.lg-toolbar {
  top: 80px; }

.lg-outer .lg-thumb-outer {
  background-color: gray;
  transition: .5s 0s; }

.lg-outer .lg-toogle-thumb {
  width: 42px;
  top: auto;
  bottom: 100%;
  margin-bottom: -1px;
  background-color: gray; }

.lg-thumb.lg-group {
  margin-left: auto;
  margin-right: auto; }

.lg-thumb-item {
  border-radius: 0 !important; }
  .lg-thumb-item:hover {
    border-color: #004ea5 !important; }

.lg-thumb-item.active {
  border-color: #B57729 !important; }

.lg-sub-html {
  background-color: white;
  color: black; }

.lg-outer.lg-pull-caption-up .lg-sub-html {
  transition: .5s 0s; }

.owl-carousel-0 .owl-stage-outer,
.owl-carousel-0 .owl-stage-outer .owl-stage,
.owl-carousel-0 .owl-stage-outer .owl-stage .owl-item {
  overflow: hidden !important; }

.owl-carousel-0.owl-drag {
  pointer-events: none !important; }

.owl-carousel-1--wrap {
  overflow-x: hidden;
  padding: 4rem 0; }

.owl-carousel-1 .card {
  border-radius: 0;
  border: 0px solid transparent; }

.owl-carousel-1 {
  z-index: auto; }

.owl-carousel-1 .owl-stage-outer,
.owl-carousel-1 .owl-stage-outer .owl-stage,
.owl-carousel-1 .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
  overflow: visible; }

.owl-carousel-2 {
  z-index: auto; }

.owl-carousel-2 .owl-stage-outer,
.owl-carousel-2 .owl-stage-outer .owl-stage,
.owl-carousel-2 .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
  overflow: visible; }

.owl-carousel-3--wrap {
  overflow: hidden; }

.owl-carousel-3 {
  z-index: auto; }

.owl-carousel-3 .owl-stage-outer,
.owl-carousel-3 .owl-stage-outer .owl-stage,
.owl-carousel-3 .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
  overflow: visible; }

.owl-carousel-4 {
  z-index: auto; }

.owl-carousel-4 .owl-stage-outer,
.owl-carousel-4 .owl-stage-outer .owl-stage,
.owl-carousel-4 .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
  overflow: visible; }
